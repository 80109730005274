<template>
  <v-app>
    <v-row v-if="loading">
      <v-col cols="12" class="mt-10">
        <v-skeleton-loader
            v-bind="attrs"
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
        <v-skeleton-loader
            v-bind="attrs"
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div class="card card-custom" v-else>
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
           Profile<br/>
          </h3>
        </div>
        <div class="card-toolbar" v-if="this.edit" >
          <v-btn
              text
              x-large
              class="ml-2 cancel-btn mr-1"
              @click="close()">
            Cancel
          </v-btn>
          <v-btn
              :disabled="buttonDisabled"
              :loading="loading"
              x-large
              dark
              @click="openWarningDialog"
          >
            Save
          </v-btn>

        </div>
        <div class="card-toolbar" v-if="this.edit!=true">
          <v-btn
              text
              x-large
              @click="loginAsAccountHolder(account_holder_profile.id)"
              class="ml-2 mr-1 cancel-btn"

          >
            Log in as an enroller
          </v-btn>
          <v-btn 
            dark
            x-large
            @click="editProfile()"
          >
            Edit
          </v-btn>

        </div>
      </div>
      <form class="form">
        <div class="card-body" v-if="this.edit">
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Title <span class="text-danger">*</span> :</label>
            <div class="col-lg-9 col-xl-6">
              <v-select
                  label="Title"
                  v-model="account_holder_profile.title"
                  :menu-props="{ top: true, offsetY: true }"
                  :items="titles"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
              </v-select>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">First name <span class="text-danger">*</span></label>
            <div class="col-lg-9 col-xl-6">
              <v-text-field outlined dense :error="$v.account_holder_profile.first_name.$error"
                            v-model="account_holder_profile.first_name"
              ></v-text-field>
              <span class="text-danger" v-if="$v.account_holder_profile.first_name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.first_name">* {{errors.first_name[0]}}<br></span>
            </div>
          </div>
        <!--          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Middle name</label>
            <div class="col-lg-9 col-xl-6">
              <v-text-field outlined dense
                            v-model="account_holder_profile.middle_name"
              ></v-text-field>

            </div>
          </div>-->
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Surname <span class="text-danger">*</span></label>
            <div class="col-lg-9 col-xl-6">
              <v-text-field
                  outlined :error="$v.account_holder_profile.last_name.$error"
                  dense
                  v-model="account_holder_profile.last_name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.last_name">* {{errors.last_name[0]}}<br></span>
              <span class="text-danger" v-if="$v.account_holder_profile.last_name.$error">This information is required</span>
            </div>
          </div>
          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Enroller type <span class="text-danger">*</span></label>
            <div class="col-lg-9 col-xl-6">

              <v-container class="px-2 mx-2" fluid row>
                <v-checkbox  value="teacher" class="mr-3"
                             v-model="roleSelected"
                             label="Teacher">
                </v-checkbox>
                <v-checkbox   value="parent" class="mr-3"
                              v-model="roleSelected"
                              label="Parent">
                </v-checkbox>
                <v-checkbox  value="adult_learner" class="mr-3"
                             v-model="roleSelected"
                             label="Adult learner">
                </v-checkbox>
              </v-container>
              <span class="text-danger" v-if="errors.role">{{errors.role[0]}}<br></span>
              <span class="text-danger" v-if="$v.roleSelected.$error">This information is required</span>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email <span class="text-danger">*</span></label
            >
            <div class="col-lg-9 col-xl-6">
              <v-text-field @blur="checkDuplicateEmail" outlined dense :error="$v.account_holder_profile.email.$error"
                            v-model="account_holder_profile.email"
              ></v-text-field>
              <span class="text-danger" v-if="$v.account_holder_profile.email.$error">This information is required</span>
              <span class="text-danger" v-if="!$v.account_holder_profile.email.email">Email must be valid</span>
              <span class="font-weight-medium text-danger" v-if="messages">{{messages}}</span>
              <span class="text-danger" v-if="errors.email" >{{errors.email[0]}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Mobile </label>
            <div class="col-lg-9 col-xl-6">
              <v-text-field
                  outlined
                  dense
                  :error="$v.account_holder_profile.mobile.$error"
                  v-model="account_holder_profile.mobile"
              ></v-text-field>
              <span class="text-danger" v-if="errors.mobile">{{errors.mobile[0]}}<br></span>
              <span class="text-danger" v-if="!$v.account_holder_profile.mobile.containTenDigit || !$v.account_holder_profile.mobile.numeric">Mobile must contain 10 digits or must be number</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"> Phone</label>
            <div class="col-lg-9 col-xl-6">
              <v-text-field
                  outlined
                  dense
                  :error="$v.account_holder_profile.phone.$error"
                  v-model="account_holder_profile.phone"
              ></v-text-field>
              <span class="text-danger" v-if="errors.phone">{{errors.phone[0]}}<br></span>
              <span class="text-danger" v-if="!$v.account_holder_profile.phone.containTenDigit || !$v.account_holder_profile.phone.numeric">Phone must contain 10 digits or must be number</span>

            </div>
          </div>


<!--          <div class="form-group row">-->
<!--            <label class="col-xl-3 col-lg-3 col-form-label text-right">Work phone</label>-->
<!--            <div class="col-lg-9 col-xl-6">-->
<!--              <v-text-field-->
<!--                  outlined-->
<!--                  dense-->
<!--                  v-model="account_holder_profile.work_phone"-->
<!--              ></v-text-field>-->
<!--            </div>-->
<!--          </div>-->
        </div>

        <div class="card-body align-items-center" v-if="this.edit!=true">
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Name:</label>
            <div class="col-lg-9 col-xl-6">
              <span class="text-dark-75 text-hover-primary" v-copy="account_holder_profile.full_name">{{account_holder_profile.full_name ? account_holder_profile.full_name : 'NA'}}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Enroller type:</label>
            <div class="col-lg-9 col-xl-6">
              <span class="text-dark-75 text-hover-primary" v-copy="account_holder_profile.role_text">{{account_holder_profile.role_text ? account_holder_profile.role_text : 'NA'}}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Email:</label>
            <div class="col-lg-9 col-xl-6">
              <span class="text-dark-75 text-hover-primary" v-copy="account_holder_profile.email">{{account_holder_profile.email ? account_holder_profile.email : 'NA'}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Mobile:</label>
            <div class="col-lg-9 col-xl-6">
              <span class="text-dark-75 text-hover-primary" v-copy="account_holder_profile.mobile">{{account_holder_profile.mobile_format ? account_holder_profile.mobile_format : 'NA'}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Phone:</label>
            <div class="col-lg-9 col-xl-6">
              <span class="text-dark-75 text-hover-primary" v-copy="account_holder_profile.phone">{{account_holder_profile.phone_format ? account_holder_profile.phone_format : 'NA' }}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Work phone:</label>
            <div class="col-lg-9 col-xl-6">
              <span class="text-dark-75 text-hover-primary" v-copy="account_holder_profile.work_phone">{{account_holder_profile.work_phone_format ? account_holder_profile.work_phone_format : 'NA' }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Notification method:</label>
            <div class="col-lg-9 col-xl-6">
              <span class="text-dark-75 text-hover-primary">{{account_holder_profile.notification_method }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">SchoolPay enabled:</label>
            <div class="col-lg-9 col-xl-6">
              <span class="text-dark-75 text-hover-primary">{{account_holder_profile.school_pay_enabled ? 'Yes' : 'No' }}</span>
            </div>
          </div>
        </div>
      </form>
    </div>

    <v-dialog
      v-model="warningDialog"
      max-width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
<!--                <div class="text-center">-->
<!--                    <i class="fa fa-triangle-exclamation" style="font-size: 5em; color:firebrick"></i>  -->
<!--                </div>-->
                <h6 class="mt-5">You’re about to save changes. Doing so will send an automated email to the Enroller to let them know that the changes have been made.</h6>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              large
              @click="closeWarningDialog">
            Cancel
          </v-btn>
          <v-btn
              dark
              x-large
              @click="saveWithoutNotifying"
              :loading="isSavingWithoutMail"
          >
            Save without notifying
          </v-btn>
          <v-btn
              dark
              x-large
              @click="saveWithNotifying"
              :loading="isSavingWithMail"
          >
            Save and send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import {numeric, email, required, requiredIf} from "vuelidate/lib/validators";
import error from "@/view/pages/error/Error";
const accountholder=new AccountholderService();
export default {
  validations:{
    account_holder_profile:{
      first_name:{required},
      last_name: {required},
      role: {},
      phone: {
        numeric,
        required: requiredIf(function () {
          return !this.account_holder_profile.mobile
        }),
        containTenDigit: function (value) {
          if(value) {
            return value.length === 10;
          }
          return true;
        }
      },
      mobile: {
        numeric,
        required: requiredIf(function () {
          return !this.account_holder_profile.phone
        }),
        containTenDigit: function (value) {
          if(value) {
            return value.length === 10;
          }
          return true;
        }
      },
      email: {required,email},
    },
    roleSelected:{}
  },
 /* props: ['account_holder_profile'],*/
  name: "AccountHolderProfile",
  data() {
    return{
      attrs:[],
      loading:true,
      edit: false,
      errors:[],
      titles:[
        {name: 'None', value: ''},
        {name: 'Ms', value: 'Ms'},
        {name: 'Miss', value: 'Miss'},
        {name: 'Mrs', value: 'Mrs'},
        {name: 'Mr', value: 'Mr'},
        {name: 'Mx', value: 'Mx'},
        {name: 'Dr', value: 'Dr'},
        {name: 'Prof', value: 'Prof'},
      ],
      account_holder_profile:{
        title:'',
        first_name:'',
        middle_name:'',
        last_name:'',
        role:'',
        phone:'',
        mobile:'',
        // work_phone:'',
      },
      roleSelected:[],
      duplciateEmailStatus:'',
      messages:'',
      buttonDisabled:false,
      warningDialog: false,
      isSavingWithoutMail: false,
      isSavingWithMail: false,
    }
  },
  mounted() {
    this.getAccountholderDetail();

  },
  methods:{
    editProfile(){
      this.edit=true;
    },
    close(){
      this.edit=false;
    },
    submit(){
      this.edit=false;
    },
    loginAsAccountHolder(accountHolderId)
    {
      accountholder.loginAsAccountHolder(accountHolderId).then(response=>{
        if(response.data.status=='OK')
        {
          let popup =  window.open(response.data.url,'_blank');
          if (!popup) {
            this.$snotify.warning('Please enable popup');
          }
        }
        else
          this.$snotify.error("Oops something went wrong");
      }).catch(error=>{

      });
    },
    saveWithNotifying(){
      this.isSavingWithMail= true;
      this.account_holder_profile['send_mail'] = 1;
      this.updateProfile();

    },
    saveWithoutNotifying(){
      this.isSavingWithoutMail = true;
      this.account_holder_profile['send_mail'] = 0;
      this.updateProfile();
    },
    updateProfile() {
      this.account_holder_profile['form_type'] = "update-accountholder-profile";
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if(this.roleSelected.length > 0){
          this.account_holder_profile.role=this.roleSelected.toString();
        }
        else{
          this.account_holder_profile.role = '';
        }
        this.loading=true;
        
        accountholder
            .update(this.account_holder_profile.id, this.account_holder_profile)
            .then(response => {
              this.$emit("update-accountholder");
              this.close();
              this.$snotify.success("Profile updated");
              this.getAccountholderDetail();
              this.loading=false;
              this.isSavingWithoutMail= false;
              this.isSavingWithMail= false;
              this.closeWarningDialog();
              this.account_holder_profile['send_mail'] = 0;
            })
            .catch((err) => {
              this.errors = err.errors;
              this.loading=false;
              this.isSavingWithoutMail= false;
              this.isSavingWithMail= false;
            });
      }
    },
    getAccountholderDetail(){
      accountholder
          .show(this.accountHolderId)
          .then(response => {
            this.account_holder_profile=response.data.accountHolder;
            if(this.account_holder_profile.role){
              this.roleSelected=this.account_holder_profile.role.split(',');
            }

          }).catch(error=>{
        this.$snotify.error("Problem occurred");
      }).finally(() => {
            this.loading = false;
          });
    },
    checkDuplicateEmail(){
      accountholder
          .checkDuplicateEmailForUpdate(this.accountHolderId,this.account_holder_profile)
          .then(response => {
            this.messages=response.data.message;
            this.duplciateEmailStatus=response.data.status;
            if(this.duplciateEmailStatus=="SUCCESS"){
              this.buttonDisabled=false;
            }else{
              this.buttonDisabled=true;
            }
          })
          .catch(error => {

          })
    },
    openWarningDialog(){
      this.warningDialog = true;
    },
    closeWarningDialog(){
      this.warningDialog = false;
    }
  },
  computed:{
    accountHolderId() {
      return this.$route.params.accountholderId;
    },

  }
}
</script>