<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Pending cart</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>
              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Date</th>
                  <th class="px-3">Amount</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="pending_carts.length > 0" v-for="(item, index) in pending_carts" :key="index">
                    <td class="width">
                      {{item.cart_created_at}}
                    </td>
                    <td class="px-3">
                      <span class="mb-1">
                        {{item.currency_symbol ? item.currency_symbol : '$' }} {{item.amount_total }}
                      </span>
                    </td>

                    <td class="px-3">

                     <span v-html="item.order_status_text"></span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown size="sm" variant="link"
                                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                    no-caret right no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" >
                              <a class="navi-link" @click="completeCart(item)">
                                <span class="navi-icon">
                                    <v-icon color="darken-2">fas fa-file-invoice</v-icon>
                                </span>
                                <span class="navi-text">Complete cart</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>

                      </template>
                    </td>
                  </tr>
                  <tr v-if="pending_carts.length == 0">
                    <td colspan="10" class="text-center">
                      <strong>No data found</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <update-cart ref="update-cart" @refresh="getAllPendingCart"></update-cart>
  </v-app>
</template>
<script>
import cartService from "@/services/cart/cartService";
import UpdateCart from "@/view/pages/view/user/general/cart/UpdateCart";

import draggable from "vuedraggable";
const cart=new cartService();
export default {
  props:['account_holder_name'],
  components: {
    UpdateCart
  },
  data(){
    return{
      isLoading:false,
      pending_carts:[],
      total: null,
      perPage: null,
      page: null,
    }
  },
  mounted() {
    this.getAllPendingCart();
  },
  methods:{
    getAllPendingCart(){
      let data={
        'user_id':this.userId,
      }
      cart
          .getPendingCart(data,this.page)
          .then(response => {
              this.pending_carts=response.data.data;
          })
          .catch(() => {

          })
          .finally(() =>
              this.isLoading = false
          )
    },
    completeCart(cart){
      this.$refs['update-cart'].updateCart(cart,this.userId,this.account_holder_name);
    },
  },
  computed: {
    userId() {
      if (this.$route.params.accountholderId)
        return this.$route.params.accountholderId;
      else if (this.$route.params.examinerId)
        return this.$route.params.examinerId;
      else if (this.$route.params.onlineMarkerId)
        return this.$route.params.onlineMarkerId;
      else
        return this.$route.params.onlineMarkerId;
    },
  }
}
</script>