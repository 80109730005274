<template>
  <v-app>
    <v-row v-if="loading">
      <v-col cols="12" >
        <v-skeleton-loader
            v-bind="attrs"
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-else>
      <span v-if="status=='Unauthorized'" class="p-8 text-danger">
        Unauthorised profile information requested. This information is not available to view.
      </span>
      <div class="d-flex flex-row" v-else>
        <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
             id="kt_profile_aside">
          <div class="card card-custom card-stretch" style="border-right: 1px solid #efefef;">
            <div class="card-body pt-4">
              <side-profile :user-detail="accountHolderDetail"></side-profile>
              <tab-list :user-detail="accountHolderDetail" @updateTabIndex="updateTabIndex"></tab-list>
            </div>
          </div>
        </div>
        <div class="flex-row-fluid ml-lg-8 pr-8">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <AccountHolderProfile
                  ref="profile"
                  :account_holder_profile ="accountHolderDetail"
                  @update-accountholder="getAccountHolderDetail"
              ></AccountHolderProfile>
            </b-tab>

            <b-tab>
              <notes></notes>
            </b-tab>

            <b-tab>
              <PracticalCandidate></PracticalCandidate>
            </b-tab>

            <b-tab>
              <ExamCandidate></ExamCandidate>
            </b-tab>

            <b-tab>
              <CourseCandidate></CourseCandidate>
            </b-tab>

            <b-tab>
              <AmebAwardCandidate></AmebAwardCandidate>
            </b-tab>

            <b-tab>
              <transaction-summary></transaction-summary>
            </b-tab>

            <b-tab>
              <support-ticket></support-ticket>
            </b-tab>

            <b-tab>
              <email-activity></email-activity>
            </b-tab>

            <b-tab>
              <addresses :user_type="'accountholder'"></addresses>
            </b-tab>

            <b-tab>
              <user-role :user_role="accountHolderDetail" @update="getAccountHolderDetail()"></user-role>
            </b-tab>

            <b-tab>
              <AccountHolderSettings  @update-accountholder="getAccountHolderDetail" :user_score="accountHolderDetail"></AccountHolderSettings>
            </b-tab>

            <b-tab>
              <security :user_security="accountHolderDetail" @update="updateProfile" ></security>
            </b-tab>


            <b-tab>
              <information-log></information-log>
            </b-tab>


            <b-tab>
              <ExaminerChildrenCheck></ExaminerChildrenCheck>
            </b-tab>

            <b-tab>
              <pending-cart :account_holder_name ="accountHolderDetail.full_name"></pending-cart>
            </b-tab>




          </b-tabs>
        </div>
      </div>

  </div>
  </v-app>
</template>

<script>
import AccountHolderProfile from "./widgets/Profile";
import AccountHolderSettings from "./widgets/Settings";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import Security from "@/view/pages/view/user/general/security/Security";
import UserRole from "@/view/pages/view/user/general/role/Role";
import ExamCandidate from "@/view/pages/view/user/general/enrolments/ExamCandidate";
import CourseCandidate from "@/view/pages/view/user/general/enrolments/CourseCandidate";
import AmebAwardCandidate from "@/view/pages/view/user/general/enrolments/AmebAwardCandidate";
import PracticalCandidate from "@/view/pages/view/user/general/enrolments/PracticalCandidate";
import Notes from "@/view/pages/view/user/general/notes/Notes";
import EmailActivity from "@/view/pages/view/user/general/email-activity/EmailActivity";
import InformationLog from "@/view/pages/view/user/general/information-log/InformationLog";
import TransactionSummary from "@/view/pages/view/user/general/transaction/TransactionSummary";
import SupportTicket from "@/view/pages/view/user/general/support-ticket/SupportTicket";
import Addresses from "@/view/pages/view/user/general/address/Address";
import SideProfile from "@/view/pages/view/user/general/side-profile/SideProfile";
import TabList from "@/view/pages/view/user/general/tab-list/TabList";
import PendingCart from "@/view/pages/view/user/general/cart/PendingCart";
import ExaminerChildrenCheck from "@/view/pages/view/user/examiner/widgets/ExaminerChildrenCheck";

const accountholder = new AccountholderService();

export default {
  name: "accountholder",
  components: {
    TabList,
    SideProfile,
    Addresses,
    SupportTicket,
    UserRole,
    TransactionSummary,
    InformationLog,
    EmailActivity,
    Notes,
    AccountHolderProfile,
    AccountHolderSettings,
    Security,
    ExamCandidate,
    CourseCandidate,
    AmebAwardCandidate,
    PracticalCandidate,
    PendingCart,
    ExaminerChildrenCheck
  },
  data() {
    return {
      attrs: [],
      tabIndex: 0,
      routeTabIndex: 0,
      accountHolderDetail:{},
      accountholder_setting:{},
      status:'none',
      loading: true
    };
  },
  mounted() {
    this.getAccountHolderDetail();
    this.routeTabIndex = this.$route.params.tabIndex;
  },
  updated() {
    this.setActiveTabByRoute();
  },
  methods: {
    updateTabIndex(tabIndex) {
      this.tabIndex = tabIndex;
      this.routeTabIndex = tabIndex;
    },
    setActiveTabByRoute()
    {

     if(this.routeTabIndex)
     {
       let target = document.querySelector(`a[data-tab="${this.routeTabIndex}"]`);

       if (target.classList.contains("navi-link")) {
         target = target.closest(".navi-link");
       }
       const tab = target.closest('[role="tablist"]');
       const links = tab.querySelectorAll(".navi-link");
       // remove active tab links
       for (let i = 0; i < links.length; i++) {
         links[i].classList.remove("active");
       }
       // set clicked tab index to bootstrap tab
       this.tabIndex = parseInt(target.getAttribute("data-tab"));
       // set current active tab
       target.classList.add("active");
       this.routeTabIndex = parseInt(this.routeTabIndex);

     }

      //this.tabIndex = 7;

    },
    getAccountHolderDetail() {
      accountholder
          .show(this.accountHolderId)
          .then(response => {
            this.accountHolderDetail=response.data.accountHolder;
            this.status=response.data.status;
            this.accountholder_setting=this.accountHolderDetail.accountholder_setting;
          })
          .catch((err) => {

          }).finally(() => {
        this.loading = false;
      });
    },
    updateProfile() {
      this.$refs['profile'].getAccountholderDetail();
    }
  },
  computed:{
    accountHolderId() {
      return this.$route.params.accountholderId;
    },

    routeTab(){
      if(this.accountHolderDetail){
        if(this.status=='Unauthorized'){
          let title = 'Unauthorised'
          return {
            title: title
          }
        }
        else {
          let title = this.accountHolderDetail.first_name+' '+this.accountHolderDetail.last_name
          return {
            title: title
          }
        }
      }
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
  }
};
</script>
