<template>
  <div class="card card-custom">
      <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Settings
        </h3>
      </div>

      <div class="card-toolbar" v-if="this.edit">
        <v-btn x-large @click="cancelEdit" class="cancel-btn mr-1" text>Cancel </v-btn>
        <v-btn  dark x-large
                @click="createOrUpdateSetting()"
        >
          Save
        </v-btn>

      </div>

        <div class="card-toolbar" v-else>
          <v-btn  dark x-large
                  @click="editSetting()"
          >
            Edit
          </v-btn>

        </div>
    </div>
    <form class="form" v-if="this.edit">
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">SCORE:</label>
          <div class="ml-4 mr-4">
          {{ user.score_name }}
          <v-btn small dark @click="changeScore" class="cancel-btn ml-5 mr-1" v-if="currentUser.access_type!='score'">Change SCORE </v-btn>
          </div>

        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Notification preference:</label>
          <div class="col-lg-8 col-xl-6">
            <div class="radio-inline">
              <label class="radio mr-2 ml-1">
                <input type="radio" v-model="setting.notification_preference" value="email"/>
                <span></span>&nbsp;&nbsp;Email</label>
              <label class="radio mr-2 ml-1">
                <input v-model="setting.notification_preference" value="postal" type="radio"/>
                <span></span>&nbsp;&nbsp;Postal</label>
            </div>
          </div>
        </div>
<!--        <div class="form-group row align-items-center" v-if="examining_scores.length > 0">-->
<!--          <label class="col-xl-4 col-lg-4 col-form-label text-right">Examining Scores</label>-->
<!--          <div class="col-lg-8 col-xl-8">-->
<!--            <div class="checkbox-inline">-->
<!--              <label class="mr-2 ml-1 badge badge-primary" v-for="(item, index) in examining_scores" :key="index">-->
<!--                <span class="text-white">{{ item.name }}</span>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="form-group row align-items-center">-->
<!--          <label class="col-xl-4 col-lg-4 col-form-label text-right">Enews:</label>-->
<!--          <div class="col-lg-8 col-xl-6">-->
<!--            <div class="checkbox-inline">-->
<!--&lt;!&ndash;              <label class="radio mr-2 ml-1">&ndash;&gt;-->
<!--&lt;!&ndash;                <input type="radio"  @click="changeNewsletterSubscription(subscription.is_newsletter_subscribed)" v-model="subscription.is_newsletter_subscribed" value="1"/>-->
<!--                <span></span>&nbsp;&nbsp;Subscribe</label>-->
<!--                <label class="radio mr-2 ml-1">-->
<!--                <input @click="changeNewsletterSubscription(subscription.is_newsletter_subscribed)" v-model="subscription.is_newsletter_subscribed" value="0" type="radio"/>-->
<!--                <span></span>&nbsp;&nbsp;Unsubscribe</label>&ndash;&gt;-->
<!--                <v-btn x-large @click="changeNewsletterSubscription(subscription.is_newsletter_subscribed)" class="cancel-btn mr-1" text>{{subscription.is_newsletter_subscribed ? 'Unsubscribe' : 'Subscribe'}} </v-btn>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="form-group row align-items-center">-->
<!--          <label class="col-xl-4 col-lg-4 col-form-label text-right">Score enews</label>-->
<!--          <div class="col-lg-8 col-xl-6">-->
<!--            <div class="checkbox-inline">-->
<!--              <v-btn x-large @click="changeScoreNewsletterSubscription(subscription.is_score_newsletter_subscribed)" class="cancel-btn mr-1" text>  {{subscription.is_score_newsletter_subscribed ? 'Unsubscribe' : 'Subscribe'}} </v-btn>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Discount:</label>
          <div class="col-lg-6 col-xl-6">
            <v-switch
                v-model="setting.has_discount"
                :label="setting.has_discount ? 'Enabled':'Disabled'"
                @change="toggleDiscount()"
                hide-details
            ></v-switch>
          </div>
        </div>

        <div class="form-group row align-items-center" v-if="setting.has_discount">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"></label>
          <div class="col-lg-6 col-xl-6">
            <v-select
                dense outlined
                label="Discount type"
                :error="$v.user_setting.discount_type.$error"
                v-model="user_setting.discount_type" :items="discount_types"
                item-text="text" item-value="value"
            ></v-select>
            <div class="text-danger" v-if="$v.user_setting.discount_type.$error">This information is required</div>
          </div>
        </div>

        <div class="form-group row align-items-center" v-if="setting.has_discount">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"></label>
          <div class="col-lg-6 col-xl-6">
              <v-text-field
                  outlined label="Discount"
                  dense :error="$v.user_setting.discount_value.$error"
                  v-model="user_setting.discount_value"
              ></v-text-field>
              <div class="text-danger" v-if="$v.user_setting.discount_value.$error">This information is required</div>
          </div>
        </div>



        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
          >SchoolPay application:</label
          >
          <div class="col-lg-6 col-xl-6">
            <v-switch @change="changeSchoolSetting"
                v-model="user_setting.enable_school_pay"
                :label="user_setting.enable_school_pay ?'Approved':'Disabled'"
                color="primary"
            ></v-switch>
          </div>
        </div>
        <div class="form-group row" v-if="user_setting.enable_school_pay">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
          >School account:</label
          >
          <div class="col-lg-6 col-xl-6">

            <v-switch
                @change="enableSchoolPay"
                v-model="user.is_school_account"
                :label="user.is_school_account ?'Active':'Inactive'"
                color="primary"
            ></v-switch>
          </div>
        </div>
        <div class="form-group row" v-if="user_setting.enable_school_pay">
          <label class="col-xl-4 col-lg-4 col-form-label text-right"
          >School name:</label>
          <div class="col-lg-6 col-xl-6">
            <v-text-field
                outlined label="School name"
                dense :error="$v.user_setting.school_name.$error"
                v-model="user_setting.school_name"
            ></v-text-field>
            <span class="text-danger" v-if="errors.school_name" >{{errors.school_name[0]}}</span>
            <div class="text-danger" v-if="$v.user_setting.school_name.$error">This field is required</div>
          </div>
        </div>
        <div class="form-group row" v-if="user_setting.enable_school_pay">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">School account:</label>
          <div class="col-lg-6 col-xl-6">
            <v-chip class="ma-2"  close @click:close="removeSchoolAccountList(index)" color="primary" outlined
                    v-if="schoolAccountList.length > 0" v-for="(account,index) in schoolAccountList" :key="index"
                    label>
              {{ account.school_name_text }}
            </v-chip>
            <v-autocomplete @change="selectSchoolAccount()"
                label="Search school account by email or school name"
                outlined
                dense :loading="schoolAccountLoading"
                v-model="schoolAccountDetail"   :items="user_school_accounts"  :search-input.sync="schoolAccountSearch"
                cache-items   item-text="school_name_text" return-object :error="$v.schoolAccountDetail.$error"
            ></v-autocomplete>
            <div class="text-danger" v-if="$v.schoolAccountDetail.$error">This field is required</div>
          </div>
        </div>
      </div>
        </form>
    <div class="card-body align-items-center" v-if="!this.edit">
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label text-right">SCORE:</label>
        <div class="col-lg-9 col-xl-6">
          {{ user.score_name }}
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label text-right">Notification preference:</label>
        <div class="col-lg-9 col-xl-6">
          {{ user_setting.notification_preference_text }}
        </div>
      </div>

<!--      <div class="form-group row">-->
<!--        <label class="col-xl-3 col-lg-3 col-form-label text-right">Enews:</label>-->
<!--        <div class="col-lg-9 col-xl-6">-->
<!--          {{ user_setting.is_newsletter_subscribed?'Subscribed':'Not subscribed' }}-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="form-group row">-->
<!--        <label class="col-xl-3 col-lg-3 col-form-label text-right">Score enews:</label>-->
<!--        <div class="col-lg-9 col-xl-6">-->
<!--          {{ user_setting.is_score_newsletter_subscribed?'Subscribed':'Not subscribed' }}-->
<!--        </div>-->
<!--      </div>-->



      <div v-if="user_setting.has_discount">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Discount type:</label>
          <div class="col-lg-9 col-xl-6">
            {{user_setting.discount_type}}
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Discount:</label>
          <div class="col-lg-9 col-xl-6">
            {{user_setting.discount_value? user_setting.discount_value:''}}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label text-right">SchoolPay account:</label>
        <div class="col-lg-9 col-xl-6">
          {{user_setting.enable_school_pay?'Enabled':'Disabled'}}
        </div>
      </div>

      <div v-if="user.is_school_account">

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">School name:</label>
          <div class="col-lg-9 col-xl-6">
            {{user.school_name}}
          </div>
        </div>
      </div>

    </div>

    <v-overlay :value="accountHolder_setting_overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
   <change-score ref="change-score" @update="getAccountholderDetail"></change-score>
   </div>
</template>

<script>
import UserSettingService from "@/services/user/UserSettingService";
import { required, requiredIf} from "vuelidate/lib/validators";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
import ChangeScore from "@/view/pages/view/user/general/score/ChangeScore";

const score = new ScoreService();
const accountholder=new AccountholderService();
const userSetting=new UserSettingService;

export default {

  name: "AccountHolderSettings",
  components: {ChangeScore},
  validations:{
    user_setting:{
      discount_type: {
        required: requiredIf(function () {
          return this.setting.has_discount==true;
        })
      },
      discount_value: {
        required: requiredIf(function () {
          return this.setting.has_discount==true;
        })
      },
      school_name: {
        required: requiredIf(function () {
          return this.user_setting.enable_school_pay;
        })
      },

    },
    schoolAccountDetail: {
      required: requiredIf(function () {
        return this.schoolAccountList.length==0 && this.user_setting.enable_school_pay;
      })
    },
  },
  /*props:['user'],*/
  data(){
    return{
      menu:false,
      dialog: false,
      isLoading:false,
      loading: false,
      school_accounts: [],
      user_school_accounts: [],
      userSearch: null,
      edit:false,
      user:{
        current_score_id:'',
        examining_scores:'',
        is_school_account: false
      },
      discount_types:[
        {text: 'Fixed (in Dollars)', value: 'fixed'},
        {text: 'Percentage (between 1 and 100)', value: 'percentage'},
      ],

      instrument_preferences:[
        {text: 'Mixed', value: 'mixed'},
        {text: 'Low to high', value: 'low_to_high'},
        {text: 'High to low', value: 'high_to_low'},

      ],
      grade_preferences:[
        {text: 'Mixed', value: 'mixed'},
        {text: 'Syllabuses grouped but any order', value: 'grouped'},
        {text: 'High instruments to low', value: 'high_to_low'},
        {text: 'Low instruments to high', value: 'low_to_high'},
      ],
      setting:{
        notification_preference:'postal',
        phone_preference:'mobile',
        has_discount:false,
        has_wwcc:false,
        user_id:'',
      },
      user_setting:{
        enable_school_pay:false,
        school_name:'',
        school_account_ids:[],
        notification_preference:'',
        notification_preference_text:'',
        phone_preference:'',
        has_discount:false,
        discount_type:'',
        discount_value:'',
        qualifications:'',
        digital_signature:null,
        grade_preferences:'',
        instrument_preferences:'',

      },
      discountShow:false,
      digital_signature_img:null,
      wwccShow:false,
      errors:[],
      scores:[],
      examining_scores:[],
      currentUser:{},

      schoolAccountDetail:{},
      schoolAccountLoading:false,
      schoolAccountSearch:'',
      schoolAccountList:[],
      accountHolder_setting_overlay:false,
      subscription:{
        is_newsletter_subscribed:0,
        is_score_newsletter_subscribed:0,
        user_id:'',
      },
    }
  },

  methods:{
    changeNewsletterSubscription(value) {
      this.$confirm({
        message: value==1?`Just checking that you're wanting to sign up to our ENews. In doing so, we'll add you to our subscriber list and email your our latest news and updates.`:`Just checking that you're wanting to unsubscribe from our ENews. In doing so, we'll remove you from our list but you may miss out on hearing the latest news and updates.`,
        button: {
          no: "Cancel",
          yes: "Confirm",
        },
        callback: (confirm) => {
          if (confirm) {
            if(value==1){
              this.subscription.is_newsletter_subscribed=0;
            }else{
              this.subscription.is_newsletter_subscribed=1;
            }
            this.subscription.user_id=this.accountholderId;
            userSetting
                .changeNewsletter(this.subscription)
                .then(response => {
                  this.findByUserId();
                  this.$snotify.success("Notification updated");
                })
                .catch((err) => {
                  console.log(err)
                }).finally(() => {

            });
          }
        },
      });
    },
    changeScoreNewsletterSubscription(value) {
      this.$confirm({
        message: value==1?`Just checking that you're wanting to sign up to our ENews. In doing so, we'll add you to our subscriber list and email your our latest news and updates.`:`Just checking that you're wanting to unsubscribe from our ENews. In doing so, we'll remove you from our list but you may miss out on hearing the latest news and updates.`,
        button: {
          no: "Cancel",
          yes: "Confirm",
        },
        callback: (confirm) => {
          if (confirm) {
            if(value==1){
              this.subscription.is_score_newsletter_subscribed=0;
            }else{
              this.subscription.is_score_newsletter_subscribed=1;
            }
            this.subscription.user_id=this.accountholderId;
            userSetting
                .changeScoreNewsletter(this.subscription)
                .then(response => {
                  this.findByUserId();
                  this.$snotify.success("Score notification updated");
                })
                .catch((err) => {
                  console.log(err)
                }).finally(() => {

              });
          }
        },
      });
    },
    editSetting()
    {
      this.edit = true;
    },

    cancelEdit()
    {
      this.edit=false;
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    changeScore() {
      this.$refs['change-score'].openDialog(this.user, 'score');
    },
    resetUserSearch() {
      this.userSearch = null;
    },
    convertToFormData() {
      if(this.setting.has_discount){
        this.user_setting.has_discount=this.setting.has_discount;
      }
      this.user_setting.notification_preference=this.setting.notification_preference;
      this.user_setting.phone_preference=this.setting.phone_preference;
      this.user_setting.user_id=this.accountholderId;
      this.user_setting.user_type='accountholder';

      if(this.user_setting.enable_school_pay){
        let school_ids =this.schoolAccountList.map((value) => value.id);
        this.user_setting.school_account_ids=school_ids.toString();
      }else{
        this.user_setting.enable_school_pay = '0';
      }
      let formData = new FormData();
      for (let key in this.user_setting) {
        if (key === "digital_signature" && this.user_setting[key] != null && this.user_setting[key] != undefined) {
          formData.append('digital_signature', this.user_setting[key]);
        }else {
          if(this.user_setting[key]){

            formData.append(key,this.user_setting[key]);
          }
        }
      }
      return formData;
    },
    toggleDiscount(){
      if(this.setting.has_discount==true){
        this.discountShow=true;
      }else{
        this.discountShow=false;
        this.$v.$reset();
      }
    },
    toggleWwcc(){
      if(this.setting.has_wwcc==true){
        this.wwccShow=true;
      }else{
        this.wwccShow=false;
      }
    },
    previewWWCCImage(){
      if(this.user_setting.wwcc_image != null){
        this.wwcc_image= URL.createObjectURL(this.user_setting.wwcc_image)
      }else{
        this.wwcc_image = null;
      }
    },
    previewDigitalSignature(){
      if(this.user_setting.digital_signature != null){
        this.digital_signature_img= URL.createObjectURL(this.user_setting.digital_signature)
      }else{
        this.digital_signature_img = null;
      }
    },
    createOrUpdateSetting(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.loading = true
        let formData = this.convertToFormData();
        this.accountHolder_setting_overlay=true;
        userSetting

            .create(formData)
            .then(response => {
              this.$snotify.success("Setting updated");
              this.findByUserId();
              this.updateAccountHolder();
              this.$emit("update-accountholder");
              this.loading = false
              this.edit = false;
            })
            .catch(error => {
              this.errors = error.errors;
              this.loading = false
            }).finally(() => {
          this.accountHolder_setting_overlay = false;
        });
      }
    },
    updateAccountHolder()
    {
      this.loading = true;
      accountholder
          .update(this.accountholderId, this.user)
          .then(response => {
            this.$emit("update-accountholder");
            this.$snotify.success("Profile updated");
            this.getAccountholderDetail();
            this.dialog = false;
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
          });
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
            //
          });
    },
    getAccountholderDetail(){
      accountholder
          .show(this.accountholderId)
          .then(response => {
            this.user=response.data.accountHolder;

            if(response.data.accountHolder.accountholder_setting.enable_school_pay){
              let school_ids=response.data.accountHolder.school_account_ids;
                this.getByIds(school_ids);

            }
            if(this.user.examining_scores){
              this.examining_scores=this.user.examining_score_list;
            }


          })
    },
    getByIds(schoolIds){
      accountholder
          .getByIds({'user_ids':schoolIds})
          .then(response => {
            this.schoolAccountList=response.data.accountHolders;
          })
          .catch((err) => {

          });
    },
    findByUserId(){
      userSetting
      .findByUserId(this.accountholderId)
          .then(response => {
            this.user_setting=response.data.userSetting;
            if(!this.user_setting){
              this.user_setting={

                enable_school_pay:false,
                school_name:'',
                notification_preference:'',
                phone_preference:'',
                has_discount:false,
                discount_type:'',
                discount_value:'',
                qualifications:'',
                digital_signature:null,
                grade_preferences:'',
                instrument_preferences:'',
              };
            }
            if(this.user_setting.is_newsletter_subscribed){
              this.subscription.is_newsletter_subscribed=this.user_setting.is_newsletter_subscribed;
            }
            if(this.user_setting.is_score_newsletter_subscribed){
              this.subscription.is_score_newsletter_subscribed=this.user_setting.is_score_newsletter_subscribed;
            }
            if(this.user_setting && response.data.userSetting.digital_signature_path){
              this.digital_signature_img=response.data.userSetting.digital_signature_path;
            }

            if(this.user_setting && this.user_setting.has_discount){
              this.setting.has_discount=this.user_setting.has_discount;
            }
            if(this.user_setting && this.user_setting.notification_preference){
              this.setting.notification_preference=this.user_setting.notification_preference;
            }
            if(this.user_setting && this.user_setting.phone_preference){
              this.setting.phone_preference=this.user_setting.phone_preference;
            }
            this.toggleDiscount();
            this.previewDigitalSignature();
          })
      },

    enableSchoolPay()
    {

    },
    changeSchoolSetting(){

      if(this.user_setting.enable_school_pay){

        this.user.is_school_account=1;
      }
    },
    selectSchoolAccount(){
      let obj = null;
      if(this.schoolAccountList.length > 0){
        let index=this.schoolAccountList.findIndex(val =>val.id==this.schoolAccountDetail.id);
        obj = this.schoolAccountList[index];
      }
      if(obj == undefined || obj == null){
        this.schoolAccountList.push(this.schoolAccountDetail);
      }else{
        this.$snotify.error('School Account has been Already Added');
      }

      this.schoolAccountDetail={};
      this.schoolAccountSearch='';
      this.user_school_accounts=[];
    },
    removeSchoolAccountList(index){
      this.schoolAccountList.splice(index,1);
    }
  },
  watch: {
    userSearch (val) {
      this.isLoading = true;
      accountholder
          .getAllByUserType(val,'')
          .then((response) => {
            response.data.accountholders.map(user => {
              let data=user;
              data.display_text=user.first_name+' '+ user.last_name;
              this.school_accounts.push(data);
            });
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    },
    schoolAccountSearch(val){
      if(val){
        let data={
          'info':val,
          'current_score_id':this.user.current_score_id,
        }
        this.schoolAccountLoading=true;
        accountholder
            .searchSchoolAccount(data)
            .then((response) => {
              this.user_school_accounts=response.data.data;
            })
            .catch(err => {

            })
            .finally(() => (this.schoolAccountLoading = false))
      }

    },

  },
  mounted() {
    this.getCurrentUser();
    this.getAllScore();
    this.findByUserId();
    this.toggleDiscount();
    this.toggleWwcc();
    this.getAccountholderDetail();
  },
  computed:{
    accountholderId() {
      return this.$route.params.accountholderId;
    },

  },
}
</script>

<style scoped>

</style>
